<!--  -->
<template>
  <div>
    <pHeader />
    <div
      class="banner"
      :style="{'background-image':'url('+require('@/assets/img/banner_about.png')+')'}"
    >
      <div class="txt-box">
        <h3>基于云原生技术的业务开发框架</h3>
        <p>让开发者以最快的速度实现完整的业务流程</p>
        <div class="btn" @click="jump('http://demo.icuapi.com')">立即体验</div>
      </div>
    </div>
    <div class="content">
      <div class="about">
        <div class="inner">
          <div class="tit">关于CASTLE</div>
          <div
            class="dec"
          >Castel是华创的一个子品牌，专注于为开发者提供快速实现业务的一款云原生框架。方便开发者快速简单的开发APP、小程序、H5、软件及其他服务平台（多端框架uniapp）。能降低每个开发者的开发工作，让你有更多的时间干其他事情。业务模板库拥有很多体系化的功能模块，开发者可以直接复制即用，省事省心。在效率变快的同时，逻辑更加完善，细节掌握的更好，能让你专注于核心业务的开发。</div>
          <div
            class="dec"
          >同时我们还提供了该框架的对外接口集成完整流程，在使用框架进行开发需要三方对接的时候，由框架出接口，其他系统进行调用。可以让客户直接去gitee上<a href="https://gitee.com/hcwdc/Fortress-OpenApi-SDK" target="_blank"><strong> 下载我们提供的SDK进行集成</strong></a>，保障了系统的安全性。</div>
          <div class="img">
            <img src="@/assets/img/about.png" alt />
          </div>
        </div>
      </div>
      <div class="contact" id="ly">
        <div class="inner">
          <div class="tit">留言给我们</div>
          <div class="from">
            <div class="line">
              <input type="text" placeholder="姓名" v-model="formData.name"/>
            </div>
            <div class="line">
              <input type="email" placeholder="邮箱" v-model="formData.param1"/>
            </div>
            <div class="line">
              <input type="tel" placeholder="电话" v-model="formData.phone"/>
            </div>
            <div class="line text">
              <textarea name id cols="30" rows="10" placeholder="内容" v-model="formData.param2"></textarea>
            </div>
            <div class="btn" @click="submit">发送</div>
          </div>
        </div>
      </div>
    </div>
    <pFooter />
  </div>
</template>

<script>
import mixin from '@/mixin'
export default {
  mixins: [mixin],
  data () {
    return {
      formData: {
        name: '',
        param1: '',
        phone: '',
        param2: ''
      }
    };
  },
  created () {
    this.$nextTick(() => {
      this.getlocal()
    })
  },

  destroyed () {
    localStorage.setItem('id', '')
  },
  components: {},

  computed: {},

  methods: {
    submit() {
        if(this.formData.name == '' || this.formData.param1 == '' || this.formData.param2 == '' || this.formData.phone == '') {
          return alert('请完善表单')
        }

      const ajax = new XMLHttpRequest()

      ajax.onreadystatechange = () => {
        if (ajax.readyState==4) {
          if (ajax.status==200) {
            this.formData.name = ''
            this.formData.param1 = ''
            this.formData.param2 = ''
            this.formData.phone = ''
            alert("提交成功");
          } else {
            alert("提交错误");
          }
        }

      }

      ajax.open('post', 'https://oa.hcses.com/hcwdc/openapi/leaveMessage/save')
      ajax.setRequestHeader('content-type', 'application/json;charset=UTF-8')
      ajax.send(JSON.stringify(this.formData))
    },

    //从本地找跳转id
    getlocal () {
      let select = localStorage.getItem('id')
      let ele = document.getElementById(select)
      if (select) {
        ele.scrollIntoView(true)
      }
    }
  }
}

</script>
<style lang='scss' scoped>
.txt-box {
  max-width: 80rem;
  margin-top: -5rem;
  text-align: left;
  @media (max-width: 750px) {
    padding-left: 2rem;
  }
  p {
    margin: -1rem 0 2rem;
    font-size: 1.6rem;
  }
  .btn {
    width: 12rem;
    height: 4rem;
    font-size: 1.4rem;
    color: #3981f7;
    background: #fff;
    border-radius: 0.4rem;
    letter-spacing: 0.1rem;
  }
}
.content {
  .about {
    .inner {
      padding: 4rem 7rem 10rem;
      max-width: $minWidth;
      margin: 0 auto;
      text-align: center;
      .tit {
        margin-bottom: 2rem;
        font-size: 2.6rem;
        color: #181818;
      }
      .dec {
        margin-bottom: 1.5rem;
        line-height: 1.6;
        font-size: 1.4rem;
        text-indent: 2rem;
        color: #666666;
        text-align: left;
      }
      .img {
        margin-top: 8rem;
        img {
          width: 40%;
        }
      }
    }
  }
  .contact {
    padding: 5rem 0;
    background: #f2f5fb;
    @media (max-width: 750px) {
      box-sizing: border-box;
      padding: 5rem 2rem;
    }
    .inner {
      max-width: $minWidth;
      margin: 0 auto;
      text-align: center;
      .tit {
        margin-bottom: 2rem;
        font-size: 2.6rem;
        color: #181818;
      }
      .from {
        max-width: 68rem;
        margin: 0 auto;
        .line {
          margin-bottom: 1rem;
          width: 100%;
          height: 3.5rem;
          input,
          textarea {
            padding: 1rem;
            width: 100%;
            height: 100%;
            background: #fff;
            border-radius: 0.2rem;
          }
        }
        .text {
          height: 10rem;
        }
        .btn {
          width: 100%;
          height: 3.8rem;
          text-align: center;
          color: #fff;
          font-size: 1.4rem;
          background: #3981f7;
          border-radius: 0.2rem;
        }
      }
    }
  }
}
@media screen and (max-width: 996px) {
  .content {
    .about {
      .inner {
        .img {
          img {
            width: 80%;
          }
        }
      }
    }
  }
}
</style>
